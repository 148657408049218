import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

function NotFoundPage() {
  return (
    <Layout>
      <div className="pageContainer error">
        <div className="error-text center">
          <h1>404</h1>
          <h3>Page not found</h3>
          <p>Sorry, the page you're looking for doesn't exist </p>
        </div>
        <div className="cta">
          <Link to="/">
            <button className="cta-action">Go Back Home</button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
